<template>
  <div class="comment-field">
    <h4>🖋 {{ $t("comment_field_title") }}</h4>
    <textarea
      v-thematic
      class="order-comment"
      :value="value"
      :disabled="completed"
      @input="$emit('input', $event.target.value)"
      :placeholder="$t('preorder_details.new_comment')"
    ></textarea>
  </div>
</template>

<style scoped>
h4 {
  background: #1d1d1d;
  color: #ededed;
  margin: 0;
  padding: 0.8vh 0.8vh 1.2vh;
  font-size: 2.2vh;
  position: relative;
  border-radius: 0.8vh 0.8vh 0 0;
}
@media screen and (min-width: 1024px) {
  h4 {
    font-size: 2vh;
    line-height: 2.3333333333333335vh;
    width: 100%;
    color: #ededed;
    text-align: center;
    position: static;
  }
  .comment-field {
    background: #1d1d1b;
    border-radius: 1.1111111111111112vh;
    overflow: hidden;
    margin: 0;
    border: 1px solid #1d1d1b;
  }
  .order-comment {
    padding: 1.1111111111111112vh 1.1111111111111112vh 1.6666666666666667vh;
    border: none;
    width: calc(100% - 2px);

    width: 100%;
    border-radius: 1.1111111111111112vh;
    resize: none;
    font-size: 1.7777777777777777vh;
    text-align: center;
    line-height: 120%;
  }
}
</style>

<script>
export default {
  props: {
    value: String,
    completed: Boolean,
  },
};
</script>

<template>
  <div class="order-detail">
    <img
      :src="currentIcon"
      :class="!value && valid? 'disabled' : ''"
    />
    <input
      type="text"
      :class="{
        'detail-input': true,
        dark: isDarkMode,
        'detail-red-input': !valid,
      }"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :placeholder="fullname ? $t('reserve.fullname_ph') : $t('reserve.name_ph')"
    />
  </div>
</template>

<style scoped>
.order-detail img {
  /* margin-right: 2.2vw !important; */
}
</style>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    fullname: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentIcon() {
      const themeSuffix = this.isDarkMode ? "-white" : "";
      if (!this.valid) return `/icons/details/person-red.svg`;
      if (this.value === null) return `/icons/details/person${themeSuffix}.svg`;
      return `/icons/details/person${themeSuffix}.svg`;
    }
  }
};
</script>

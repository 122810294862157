import BaseAPI from '../base'

function prepare(link) {
    return BaseAPI.sendPost('reserve/prepare', {
        link
    })
}

function create(data) {
    return BaseAPI.sendPost(`reserve/create`, data);
}

function getWebStatus(id) {
    return BaseAPI.sendGet(`reserve/web_status/${id}`);
}

export default {
    prepare,
    create,
    getWebStatus
}
<template>
  <div>
    <div @click="$emit('close')" :class="displayAsBlock ? '':'order-status-backdrop'"></div>
    <div :class="displayAsBlock ? 'order-status-notif w-100':'order-status-notif' " v-if="info">
      <div class="order-header-info">
        <h4 class="notif-title">
          {{ info.cafe_name }}
        </h4>
        <span class="notif-id">id:{{ info.local_id }}</span>
      </div>
      <span
        :class="{
          'notif-mode': true,
          [info.mode]: true,
        }"
      >
        {{ info.mode == 'preorder' ? 'TAKEAWAY' : info.mode.toUpperCase() }}
      </span>
      <div class="order-status-list">
        <template v-for="(entry, index) in info.statuses">
          <img
            :src="
              entry.changed_at || entry.status == 'confirmed'
                ? `/icons/webstatus/vertical-line.svg`
                : `/icons/webstatus/vertical-dash.svg`
            "
            class="line"
            :key="index + 1"
          />
          <div class="status-box" :key="index + 2">
            <div class="status-pic">
              <img
                :src="`/icons/webstatus/${entry.status}.svg`"
                :class="{
                  waiting: !entry.changed_at && entry.status != 'confirmed',
                }"
              />
            </div>
            <div
              :class="{
                'status-text': true,
                waiting: !entry.changed_at && entry.status != 'confirmed',
              }"
              v-html="
                $t(
                  `order_webstatuses.${entry.status}_${
                    entry.changed_at ? 'on' : 'off'
                  }`,
                  info
                )
              "
            ></div>
            <div class="status-timer" v-if="entry.changed_at">
              {{ entry.changed_at | formatTime }}
              <br />
              <small class="day">
                {{ entry.changed_at | formatDate }}
              </small>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PreorderAPI from "@/api/preorder";
import ReserveAPI from "@/api/reserve";

import moment from "moment";

export default {
  props:{
    displayAsBlock: {
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      info: null,

      interval_id: null,
      timer_id: null,
    };
  },
  methods: {
    fetchInfo() {
      const value = window.localStorage.getItem("web_status_id");

      if (!value) return;

      //Backward-compatability, in older version only preorder_id was supported
      //and stored in web_status_id, then
      //we added other modes via colon (<mode>:<global order id>)
      //so we need an additional check here
      const tokens = value.split(":");

      let mode = "preorder";
      let id = tokens[0];

      if (tokens.length >= 2) {
        mode = tokens[0];
        id = tokens[1];
      }

      const promise =
        mode == "preorder"
          ? PreorderAPI.getWebStatus(id)
          : ReserveAPI.getWebStatus(id);

      promise
        .then((data) => {
          if (!data.local_id) return;

          this.info = data;

          this.tickTimer();

          if (data.expired) {
            window.localStorage.removeItem("web_status_id");
            this.$emit('expired');
          }

          if (data.waiting_time) {
            this.$emit('waiting_time', data.waiting_time);
          }

          this.$emit('update', this.info);

          if (this.info) {
            this.$emit("show");
            if (!this.interval_id) {
              this.interval_id = window.setInterval(this.fetchInfo, 15 * 1000);
              this.timer_id = window.setInterval(this.tickTimer, 2 * 1000);
              this.tickTimer();
            }
          }
        })
        .catch((err) => {
          // window.localStorage.removeItem("web_status_id");
          console.log(err);
        });
    },
    tickTimer() {
      if (!this.info) return;

      if (!this.info.serve_date) {
        this.$emit("timer", null);
      } else {
        const diff = moment.duration(moment(this.info.serve_date).diff(moment()));

        if (diff <= 0) {
          this.$emit("timer", null);
        } else {
          // const minutes = diff.minutes();
          // const hours = diff.hours();
          // const seconds = diff.seconds();
          
          // let timing = '';

          // if (hours) timing += `${hours.toString().padStart(2, '0')}:`
          // timing += `${minutes.toString().padStart(2, '0')}:`;
          // timing += `${seconds.toString().padStart(2, '0')}`;
          // this.$emit('timer', timing)
          this.$emit('timer', diff.format('hh:mm:ss', {
            trim: false
          }))


          // const minutes = Math.floor(diff / 60);
          // const seconds = diff % 60;
          // const m_s = `${minutes}`.padStart(2, "0");
          // const s_s = `${seconds}`.padStart(2, "0");
          // this.$emit("timer", `${m_s}:${s_s}`);
          // this.$emit('timer', diff.format('HH:mm:'))
        }
      }
    },
  },
  beforeDestroy() {
    window.clearInterval(this.interval_id);
  },
  computed: {
  
  },
  mounted() {
    this.fetchInfo();
  },
};
</script>

<style scoped>
.order-status-notif {
  position: absolute;
  top: 7.7vh;
  left: 0;
  width: 100%;
  padding: 1.8vh;
  background: #1d1d1b;
  border-radius: 0px 0px 2vh 2vh;
  min-height: 20vh;
  z-index: 3;
}

.order-header-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

h4.notif-title {
  font-size: 2.5vh;
  color: #ededed;
  line-height: 1;
  margin: 0;
}

span.notif-id {
  float: right;
  color: #908f8d;
  font-size: 1.7vh;
}

span.notif-mode.preorder {
  font-size: 1.7vh;
  color: #ffd2a8;
}

span.notif-mode.delivery {
  font-size: 1.7vh;
  color: #cbd9e6;
}

span.notif-mode.reserve {
  font-size: 1.7vh;
  color: #aeccc6;
}

.order-status-list {
  padding-top: 1.3vh;
}

img.line {
  margin-left: 0.6vh;
  height: 4vh;
  width: auto;
}

.status-box {
  display: flex;
  width: 100%;
  align-items: center;
}

.status-pic img {
  height: 5vh;
  width: auto;
}

.status-text {
  line-height: normal;
  font-size: 1.7vh;
  padding-left: 1.2vh;
  color: #ededed;
  padding-right: 1.2vh;
  width: 100%;
}

.status-timer {
  color: #908f8d;
  line-height: normal;
  text-align: right;
  font-size: 1.7vh;
}

small.day {
  font-size: 1.2vh;
}

img.waiting {
  opacity: 0.4;
}

.status-text.waiting {
  color: #908f8d;
}

.c-grey {
  color: #908f8d;
}

 

@media screen and (min-width: 1024px) {
  .order-status-notif {
    top: 6.222222222222222vh;
    padding: 1.7777777777777777vh 3.3333vh 2.2222222222222223vh;
    width: 30.21%;
    border-radius: 0px 0px 1.11111vh 0px;
    border-top: 0.1111vh solid #908f8d;
  }
  .order-status-backdrop {
    position: absolute;
    top: 6.222222222222222vh;
    left: 0;
    width: 100vw;
    height: 93.77777777777777vh;
    background: rgba(0, 0, 0, 0.3);
  }
  .order-status-list {
    padding-top: 1.6666666666666667vh;
  }
  h4.notif-title {
    font-size: 2.2222vh;
    line-height: 2.5555555555555554vh;
  }
  span.notif-id {
    font-size: 1.4444444444444444vh;
    line-height: 1.6666666666666667vh;
  }
  img.line {
    height: 2.5555555555555554vh;
    margin-left: 1vh;
    margin: 0.4444444444444444vh 1vh;
  }
  .status-pic {
    height: 4.555555555555555vh;
  }
  .status-text {
    padding: 0 1.1111111111111112vh;
  }
  .status-timer {
    font-size: 1.4444444444444444vh;
    line-height: 1.6666666666666667vh;
  }
  span.notif-mode {
    font-size: 1.4444444444444444vh;
    line-height: 1.6666666666666667vh;
  }
}
</style>

import BaseAPI from '../base'

function check(cafe_id, code, client_id, mode, language) {
    return BaseAPI.sendGet(`/promocodes/check`, {
        cafe_id,
        code,
        client_id,
        mode,
        language
    });
}

function checkCafe(cafe_id) {
    return BaseAPI.sendGet(`/promocodes/check_cafe`, {
        cafe_id
    });
}

export default {
    check,
    checkCafe
}
<template>
  <div class="reserve-cost-box" v-if="price">
    <span class="title">🏷 {{ $t("reserve_info.title") }}
      <span class="color-black float-right">
        {{currency.symbol}}{{ price }}
      </span>
    </span>
    <p class="info">
      {{ 
        $t("reserve_info.paid") 
      }} 
      
    </p>
  </div>
</template>

<style scoped>
.reserve-cost-box {
  width: 100%;
  display: block;
  padding: 2vh;
  margin: 2.5vh auto 0;
  background: #aeccc6;
  border-radius: 1.2vh;
}

span.title {
  display: block;
  font-size: 2.3vh;
  line-height: 1;
  color: #1d1d1d;
}

p.info {
  margin: 0;
  display: block;
  font-size: 2vh;
  padding-top: 2vh;
  line-height: 1;
  color: #464646;
}

span.deposit-price {
  color: #129b81;
}
</style>

<script>
export default {
  props: {
    price: Number
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    }
  }
}
</script>
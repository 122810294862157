var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.displayAsBlock ? '':'order-status-backdrop',on:{"click":function($event){return _vm.$emit('close')}}}),(_vm.info)?_c('div',{class:_vm.displayAsBlock ? 'order-status-notif w-100':'order-status-notif'},[_c('div',{staticClass:"order-header-info"},[_c('h4',{staticClass:"notif-title"},[_vm._v("\n        "+_vm._s(_vm.info.cafe_name)+"\n      ")]),_c('span',{staticClass:"notif-id"},[_vm._v("id:"+_vm._s(_vm.info.local_id))])]),_c('span',{class:( _obj = {
        'notif-mode': true
      }, _obj[_vm.info.mode] = true, _obj )},[_vm._v("\n      "+_vm._s(_vm.info.mode == 'preorder' ? 'TAKEAWAY' : _vm.info.mode.toUpperCase())+"\n    ")]),_c('div',{staticClass:"order-status-list"},[_vm._l((_vm.info.statuses),function(entry,index){return [_c('img',{key:index + 1,staticClass:"line",attrs:{"src":entry.changed_at || entry.status == 'confirmed'
              ? "/icons/webstatus/vertical-line.svg"
              : "/icons/webstatus/vertical-dash.svg"}}),_c('div',{key:index + 2,staticClass:"status-box"},[_c('div',{staticClass:"status-pic"},[_c('img',{class:{
                waiting: !entry.changed_at && entry.status != 'confirmed',
              },attrs:{"src":("/icons/webstatus/" + (entry.status) + ".svg")}})]),_c('div',{class:{
              'status-text': true,
              waiting: !entry.changed_at && entry.status != 'confirmed',
            },domProps:{"innerHTML":_vm._s(
              _vm.$t(
                ("order_webstatuses." + (entry.status) + "_" + (entry.changed_at ? 'on' : 'off')),
                _vm.info
              )
            )}}),(entry.changed_at)?_c('div',{staticClass:"status-timer"},[_vm._v("\n            "+_vm._s(_vm._f("formatTime")(entry.changed_at))+"\n            "),_c('br'),_c('small',{staticClass:"day"},[_vm._v("\n              "+_vm._s(_vm._f("formatDate")(entry.changed_at))+"\n            ")])]):_vm._e()])]})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="promocode-box" v-show="cafe_has_promocodes">
    <h4 v-thematic :ignoreDark="false" :class="ignoreDark ? 'ignore-dark':''" >🏷 {{ $t("promocode.title") }}</h4>
    <input
      type="text"
      class="promocode"
      v-model="local_promocode"
      :class="{
        validated: valid_state == 1,
        'not-validated': valid_state == -1,
      }"
      @blur="check"
      @keyup.enter.native="check"
      :placeholder="$t('promocode.input')"
    />
    <p v-thematic v-if="valid_state == 1">
      <!-- {{
        $t("promocode.promo_types." + promocode_info.promo_type, promocode_info)
      }} -->
      {{ promocode_info.localized_description }}
    </p>
    <p v-thematic v-if="valid_state == -1" class="not-validated">
      {{ $t("promocode.wrong_code") }}
    </p>
  </div>
</template>

<script>
import PromocodesAPI from "@/api/promocodes";

export default {
  data() {
    return {
      valid_state: 0,

      local_promocode: null,

      promocode_info: null,

      cafe_has_promocodes: false,
    };
  },
  props: {
    cafe_id: [Number, String],
    mode: String,
    ignoreDark:Boolean
  },
  watch: {
    local_promocode() {
      this.$emit('input', this.local_promocode);
    }
  },
  methods: {
    checkCafe() {
      PromocodesAPI.checkCafe(this.cafe_id).then(data => {
        this.cafe_has_promocodes = data.has_promocodes;
      }).catch(err => {
        
      });
    },
    check() {
      PromocodesAPI.check(
        this.cafe_id,
        this.local_promocode,
        this.$store.state.clients.client_id,
        this.mode,
        this.$i18n.locale
      )
        .then((data) => {
          if (data.promocode) {
            this.valid_state = 1;
            this.promocode_info = data.promocode;
            this.$emit("input", this.local_promocode);
          } else {
            this.valid_state = -1;
            this.$emit("input", null);
          }
        })
        .catch((error) => {});
    },
  },
  computed: {},
  mounted() {
    this.checkCafe();
  }
};
</script>

<style scoped>
.promocode-box {
  /* padding-top: 3.2vh; */
}

.promocode-box h4 {
  font-size: 2vh;
  color: #1d1d1d;
  margin-bottom: 1.2vh;
}

h4.dark.ignore-dark {
  color: #1d1d1d;
}

h4.dark {
  color: #ededed;
}

.promocode-box p.dark {
  color: #a4a3a1;
}

input.promocode {
  background: #fff;
  width: 100%;
  outline: none;
  padding: 1.4vh;
  font-size: 1.8vh;
  border-radius: 1vh;
  border: 1px solid #908f8d;
  color: #1d1d1d;
}

input.promocode.validated {
  transition: 0.1s;
  background-image: url("../../assets/correct.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  border: 1px solid #129b81;
}

input.promocode.not-validated {
  border: 1px solid #e2223b;
}

.promocode-box p {
  font-size: 1.8vh;
  color: #464646;
  margin: 0;
  padding-top: 0.7vh;
}

.promocode-box p.not-validated {
  color: #e2223b;
}
@media screen and (min-width: 1024px) {
  .promocode-box h4 {
    font-size: 2vh;
    line-height: 118.5%;
  }
  .promocode {
    font-size: 1.7777777777777777vh;
    line-height: 2vh;
  }
  .promocode p {
    font-size: 1.6666666666666667vh;
    line-height: 118.5%;
  }
}
</style>

<template>
  <div>
    <OrderLoader v-if="loading && !error" />
    <LoadError :error="error" v-if="error" />

    <OrderModalTemplate
      titleMode="RESERVE"
      modeBgColor="#AECCC6"
      :modePicture="'/hero-pics/choose-system.svg'"
      :motd="cafe.reserve.motd"
      @close="order_modal_shown = false"
      :cafe="cafe"
      :buttonText="$t('choose_mode.go_reserve')"
      v-if="order_modal_shown"
    />

    <div class="reserve-content" v-if="!loading && !error">
      <!-- <nav>
        <img :src="cafe.image" class="cafe-logo">
        <h4>{{ cafe.name }}</h4>
        <small>Reserve</small>
      </nav> -->
      <Navbar
        :cafe="cafe"
        :target="{ reserve: true }"
        :showArrowBack="showArrowBack"
        @go-back="navigateBack"
      />
      <div class="reserve-box" v-thematic v-if="!reservationMade">
        <!-- <h5>{{ $t('reserve.header') }}</h5> -->
        <ClientNameField
          v-model="reservation_info.name"
          :valid="validation.name"
          class="p-0"
        />
        <ClientPhoneField
          v-model="reservation_info.phone"
          @click="phone_confirm_ui = true"
          @clear="reservation_info.phone = null"
          :valid="validation.phone"
        />
        <ChooseTimeField
          v-model="reservation_info.reserve_date"
          :allow_asap="false"
          :timezone="cafe.timezone"
          :dates="reservationDates"
        />
        <PersonsAmountField v-model.number="reservation_info.guests_count" />

        <ReserveInfo :price="cafe.reserve.reservation_price" />

        <!-- <PromocodeInput mode="reserve" :cafe_id="cafe._id"/> -->

        <PayMethods
          v-model="details.pay_method"
          :validation="validation.pay_method"
          :available_methods="{
            cash: true,
            online: true,
            card: false,
            bonus: false,
          }"
        />

        <CommentField v-model="details.comment" class="mt-30" />

        <SupportIcon class="support-icon-container" />
      </div>

      <div v-if="reservationMade" class="reserve-card">
        <div class="order-id">
          <h3 class="m-0">RESERVE</h3>
          <span>
            id:
            <h3 class="d-inline">{{ reservation.local_id }}</h3>
          </span>
        </div>

        <div class="client-name">
          <h4>{{ reservation.name }}</h4>
        </div>
        <div class="order-details">
          <div class="pt-0">
            <img src="/icons/persons.svg" />
            <!-- <p class="order-info">{{ $t('reserve.card_guests_count') }}</p> -->
            <p class="float-right">
              {{ $tc("reserve.guests_count", reservation.guests_count) }}
            </p>
          </div>
          <div>
            <img src="/icons/time.svg" />
            <!-- <p class="order-info">{{ $t('reserve.card_reserve_time') }}</p> -->
            <p class="float-right">
              {{ reservation_info.reserve_date | formatReservationTime }}
            </p>
          </div>
          <div>
            <img src="/icons/date.svg" />
            <!-- <p class="order-info">{{ $t('reserve.card_reserve_date') }}</p> -->
            <p class="float-right">
              {{ reservation_info.reserve_date | formatReservationDate }}
            </p>
          </div>
          <div>
            <img src="/icons/mobile.svg" />
            <!-- <p class="order-info">{{ $t('reserve.card_phone') }}</p> -->

            <p class="float-right">{{ reservation.phone }}</p>
          </div>
        </div>
      </div>

      <CommentField
        v-if="reservationMade && details.comment"
        completed
        v-model="details.comment"
        class="w-90 m-auto mt-30"
      />

      <OrderStatusNotification
        class="display-as-block"
        v-if="reservationMade"
      />

      <!-- order status -->

      <ContactIcons :cafe="cafe" v-if="reservationMade" />

      <FeedbackButton
        blackOnly
        v-if="reservationMade && !feedback_sent"
        @click="show_feedback = true"
      />

      <!-- <SupportIcon v-if="reservationMade" /> -->

      <img
        src="../assets/logo.png"
        v-if="reservationMade && !isDarkMode"
        class="qrwaiter-small-logo"
      />
      <img
        src="/icons/logo.svg"
        v-if="reservationMade && isDarkMode"
        class="qrwaiter-small-logo"
      />

      <router-link to="/marketplace">
        <button v-if="reservationMade" class="market-btn" v-thematic>
          {{ $t("reserve.back_to_market") }}
        </button>
      </router-link>

      <!-- <button
        v-if="reservationMade"
        class="download-app mt-3"
        @click="downloadApp"
      >
        {{ $t("card.download_app") }}
      </button> -->

      <div class="btns-container" v-if="!reservationMade">
        <div class="reserve-btn" @click="createReservation" v-thematic>
          <h4>{{ $t("reserve.action") }}</h4>

          <small
            >{{ $tc("reserve.guests_count", reservation_info.guests_count) }} |
            {{ reservation_info.reserve_date | formatReservationDate }} |
            {{ reservation_info.reserve_date | formatReservationTime }}</small
          >
        </div>
      </div>
    </div>

    <PhoneConfirmModal
      :token="confirming_token"
      @confirmed="onPhoneConfirmed"
      @resend="askPhoneConfirmation"
      @cancel="confirming_phone = false"
      v-if="confirming_phone"
    />

    <OnlinePaymentModal
      :order_id="reservation._id"
      :mode="'reserve'"
      @close="pay_modal = false"
      @success="onPaySuccess"
      v-if="pay_modal"
    />

    <CafeFeedback
      :order_id="reservation._id"
      :mode="'reserve'"
      @success="
        show_feedback = false;
        feedback_sent = true;
      "
      @cancel="show_feedback = false"
      v-if="show_feedback"
    />

    <PhoneConfirmationUI
      :initial_phone="reservation_info.phone"
      @close="phone_confirm_ui = false"
      @confirm="onNewPhoneConfirmed"
      v-if="phone_confirm_ui"
    />
  </div>
</template>


<style scoped>
.reserve-content {
  padding-bottom: 12vh;
}

nav {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  flex-flow: column;
  background: #1d1d1b;
  line-height: normal;
  padding: 1.5vh 2.5vh;
}

.c-grey {
  color: #a4a3a1;
}

.mt-30 {
  margin-top: 3.7vh;
}

.w-90 {
  width: 90%;
}

.cafe-logo {
  height: 4vh;
  width: auto;
  object-fit: cover;
  border-radius: 5vh;
  position: absolute;
}

nav h4 {
  padding-left: 5vh;
  margin: 0;
  font-size: 2vh;
  color: #ededed;
  line-height: normal;
}
nav small {
  padding-left: 5vh;
  font-size: 1.7vh;
  color: #a4a3a1;
}

.market-btn {
  background: transparent;
  border: 1px solid #1d1d1d;
  color: #1d1d1d;
  margin: 0 auto;
  display: block;
  width: 90%;
  padding: 1.6vh 0;
  font-size: 2vh;
  border-radius: 5vh;
}

.market-btn.dark {
  color: #ededed;
  border: 1px solid #ededed;
}

.download-app {
  width: 90%;
  margin: 0 auto;
  display: block;
  color: #ededed;
  padding: 1.6vh 0;
  font-size: 2vh;
  border-radius: 5vh;
  border: none;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  background: #1d1d1b;
}

.reserve-box {
  background: #ededed;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 10.4vh 2.5vh 0;
  position: relative;
}

.reserve-box.dark {
  background: #000;
  color: #ededed;
}

.order-id span {
  width: 100%;
  color: #1d1d1d;
  text-align: right;
  font-size: 4vw;
}

.reserve-detail {
  display: flex;
  color: #1d1d1b;
  padding: 2vh 0;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #a4a3a1;
}

.reserve-detail.dark {
  color: #a4a3a1;
}

.reserve-detail img {
  height: 3.5vh;
  width: 3.5vh;
  margin-right: 2vh;
}
.inherit {
  width: inherit;
}

.time-selector-box {
  height: 4vh;
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: inherit;
}

p {
  margin: 0;
  width: max-content;
  line-height: normal;
  align-self: center;
  font-size: 2vh;
}
h5 {
  color: #a4a3a1;
  margin: 0;
  text-transform: uppercase;
  font-size: 2vh;
  padding-bottom: 1vh;
  border-bottom: 1px solid #a4a3a1;
}

p.selective {
  color: #129b81;
}

.disabled {
  opacity: 0.45;
}

.selective-item {
  width: inherit;
}
.selective-item img {
  float: right;
  transform: rotate(-90deg);
  margin: 0;
  width: auto;
  height: 1.5vh;
}

select:focus ~ .selective-item img {
  transform: rotate(180deg);
  transition: 0.3s;
}

select {
  width: 85%;
  position: absolute;
  background: transparent;
  border: none;
  opacity: 0;
}

.btns-container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.21) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 2.4vh 5.4vw;
  margin: 0;
  bottom: -0.1vh;
}

.back-btn {
  background: #aeccc6;
  border-radius: 5vh;
}

.back-btn img {
  height: 2.3vh;
  width: 2.3vh;
  margin: 2.5vh;
}

.reserve-btn {
  display: flex;
  flex-flow: column wrap;

  justify-content: space-around;
  border-radius: 1.5vh;
  width: 100%;
  text-align: center;
  background: #1d1d1b;
  padding: 1.6vh;
}

.reserve-btn h4 {
  font-size: 2vh;
  line-height: initial;
  margin: 0;
  color: #ededed;
}

.reserve-btn small {
  color: #a4a3a1;
  line-height: normal;
  display: block;
  font-size: 1.5vh;
}

.reserve-btn.dark {
  background: #aeccc6;
}

.reserve-btn.dark h4 {
  color: #1d1d1b;
}

.reserve-btn.dark small {
  color: #464646;
}

.reserve-card {
  width: 90%;
  position: relative;
  margin: 10vh auto 2.5vh;
  background: #aeccc6;
  display: block;
  padding: 0;
  border-radius: 1.8vh;
}

.reserve-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.reserve-head span {
  font-size: 2vh;
  align-items: baseline;
  display: inline-flex;
  color: #1d1d1d;
}

.reserve-card h4 {
  font-weight: 500;
  color: #1d1d1b;
  padding: 2.5vh;
}

.client-name h4 {
  font-size: 6.7vw !important;
  padding: 0;
  color: #aeccc6;
  font-weight: normal;
}

header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.reserve-card h4 {
  font-size: 3.7vh;
  font-weight: 500;
  margin: 0;
  line-height: 0.8;
}
span {
  font-size: 2vh;
  line-height: 1;
  align-self: center;
  color: #a4a3a1;
}
p.content {
  font-size: 2vh;
  margin: 0;
  line-height: normal;
  padding-top: 1.1vh;
  color: #454543;
}

.client-name {
  width: 100%;
  background: #1d1d1b;
  color: #ededed;
  padding: 1.3vh 5.4vw;
}

.order-details {
  padding: 2.5vh 5.5vw 2.5vh;
}

.order-details p {
  line-height: inherit;
}

.order-details img {
  height: 2.3vh;
  width: auto;
}

.order-details div {
  padding-top: 1.9vh;
}
p.order-info {
  line-height: normal;
  padding-left: 1vh;
  margin-bottom: 0;
  display: inline-block;
}

.detail-input {
  border: 0;
  outline: 0;
  -webkit-appearance: none;
  color: #1d1d1b;
  background: transparent;
}

.detail-input.dark {
  color: #ededed;
}

.detail-red-input {
  /* border: 1px solid #e2223b; */
  color: #e2223b;
}

.detail-red-input::placeholder {
  color: #e2223b;
}

img.qrwaiter-small-logo {
  height: 6.2vh;
  width: auto;
  margin: 9.9vh auto;
  display: block;
  opacity: 0.3;
}

.support-icon-container {
  margin-bottom: 14vh;
}

.display-as-block {
  position: relative;
  width: 90%;
  z-index: 1;
  margin: 0 auto;
  border-radius: 1.2vh;
  top: 0;
  margin-top: 2.5vh;
}
</style>

<script>
import OrderLoader from "@/components/OrderLoader.vue";
import LoadError from "@/components/LoadError.vue";

import PhoneConfirmModal from "@/components/modals/PhoneConfirmModal.vue";

import FeedbackButton from "@/components/FeedbackButton.vue";
import CafeFeedback from "@/components/CafeFeedback.vue";

import ReserveInfo from "@/components/card/ReserveInfo.vue";
import PromocodeInput from "@/components/order-details/PromocodeInput.vue";

import OrderModalTemplate from "@/components/modals/OrderModalTemplate.vue";

import OnlinePaymentModal from "@/components/modals/OnlinePaymentModal.vue";

import ClientNameField from "@/components/order-details/ClientNameField.vue";
import ClientPhoneField from "@/components/order-details/ClientPhoneField.vue";
import ChooseTimeField from "@/components/order-details/ChooseTimeField.vue";
import PersonsAmountField from "@/components/order-details/PersonsAmountField.vue";
import CommentField from "@/components/order-details/CommentField.vue";
import ContactIcons from "@/components/order-details/ContactIcons.vue";

import ReserveAPI from "@/api/reserve";
import SecurityAPI from "@/api/security";

import moment from "moment";

import ScheduleService from "@/services/schedule";
import MarketplaceService from "@/services/marketplace";
import PayMethods from "@/components/PayMethods.vue";
import OrderStatusNotification from "@/components/OrderStatusNotification.vue";

import PhoneConfirmationUI from "@/components/modals/PhoneConfirmationUI.vue";

import SupportIcon from "@/components/SupportIcon.vue";

import Navbar from "@/components/Navbar.vue";

import { IMaskDirective } from "vue-imask";

export default {
  props: {
    cafe: Object,
  },
  data() {
    return {
      loading: true,
      error: null,
      cafe: {},
      order_modal_shown: true,
      details: {
        name: null,
        phone: null,
        takeaway_date: "none",
        guests_count: null,
        pay_method: null,
        comment: null,
      },

      reservation_info: {
        name: null,
        phone: null,
        reserve_date: null,
        cafe_id: null,
        guests_count: 1,
        client_id: null,
        marketplace_order: false,
      },

      phone_mask: {
        mask: "{+38}-000-000-00-00",
        lazy: false,
      },

      validation: {
        phone: true,
        name: true,
        pay_method: true,
      },

      confirming_phone: false,
      confirming_token: null,

      pay_modal: false,

      phone_confirm_ui: false,

      reservation: null,

      show_feedback: false,
      feedback_sent: false,
    };
  },
  watch: {
    error() {
      console.error(this.error);
    },
    "reservation_info.name"() {
      if (this.reservation_info.name) {
        this.validation.name = true;
      }
    },
    "reservation_info.phone"() {
      if (this.reservation_info.phone) {
        this.validation.phone = true;
      }
    },
    "details.pay_method"() {
      if (this.details.pay_method) {
        this.validation.pay_method = true;
      }
    },
  },
  methods: {
    fetchData() {
      ReserveAPI.prepare(this.$route.params.code)
        .then((data) => {
          this.loading = false;
          this.cafe = data.cafe;
          this.reservation_info.cafe_id = this.cafe._id;
          this.reservation_info.reserve_date = this.reservationDates[0];

          this.$store.dispatch("worldwide/init", data.country);

          this.checkClientCache();
        })
        .catch((error) => {
          this.error = error;
        });
    },
    checkClientCache() {
      if (this.$store.getters["clients/isAuthorized"]) {
        const client = this.$store.state.clients.client;

        this.reservation_info.name = client.name;
        this.reservation_info.phone = client.phone;

        this.reservation_info.client_id = client._id;
      }
    },
    onNewPhoneConfirmed(value) {
      this.reservation_info.phone = value;
    },
    createReservation() {
      this.validation.name = true;
      this.validation.phone = true;

      if (
        !this.reservation_info.name ||
        this.reservation_info.name.length < 3
      ) {
        this.validation.name = false;
      }

      if (
        !this.reservation_info.phone ||
        this.reservation_info.phone.length < 7
      ) {
        this.validation.phone = false;
      }

      if (!this.validation.name || !this.validation.phone) return;

      if (
        !this.$store.getters["clients/isAuthorized"] ||
        this.$store.state.clients.client.phone != this.reservation_info.phone
      ) {
        this.askPhoneConfirmation();
        return;
      }

      ReserveAPI.create(this.reservation_info)
        .then((data) => {
          window.localStorage.setItem(
            "web_status_id",
            `reserve:${data.reservation._id}`
          );
          this.reservation = data.reservation;

          if (this.reservation.status == "pending") {
            this.openPayModal();
          }
        })
        .catch((error) => {
          alert(`Помилка (${error}) :(`);
        });
    },

    formatReservationDay(raw) {
      const m = moment(raw);
      const now = moment();

      if (now.isSame(m, "day")) return this.$t("reserve.today");
      if (now.add(1, "day").isSame(m, "day"))
        return this.$t("reserve.tomorrow");

      return m.format("DD.MM.YYYY");
    },
    askPhoneConfirmation() {
      this.confirming_phone = true;

      SecurityAPI.ask(this.reservation_info.phone)
        .then((data) => {
          this.confirming_token = data.token;
        })
        .catch((error) => {
          alert(
            `Сталася помилка підтвердження. Будь ласка, спробуйте пізніше.`
          );
        });
    },
    onPhoneConfirmed() {
      this.$store
        .dispatch("clients/create", {
          name: this.reservation_info.name,
          phone: this.reservation_info.phone,
        })
        .finally(() => {
          this.reservation_info.client_id =
            this.$store.state.clients.client._id;
          this.confirming_phone = false;
          this.$nextTick(this.createReservation);
        });
    },
    downloadApp() {
      this.$router.push("/download");
    },
    openPayModal() {
      this.pay_modal = true;
    },
    onPaySuccess() {
      console.log(`Received success event from payment`);
      this.reservation.status = "confirmed";
    },
    navigateBack() {
      // if (this.hasOtherModesEnabled) {

      // } else {
      //   this.$router.push({
      //     name: "marketplace",
      //   });
      // }
      this.$router.push({
        name: "choose_mode",
        params: {
          code: this.$route.params.code,
        },
      });
    },
  },
  computed: {
    maxGuestsCount() {
      if (!this.cafe.reserve || !this.cafe.reserve.max_guests_count) {
        return 5;
      }

      return this.cafe.reserve.max_guests_count;
    },
    maxDaysForDate() {
      return 30;
      // if (!this.cafe.reserve || !this.cafe.reserve.max_days) {
      //   return 7;
      // }

      // return this.cafe.reserve.max_days;
    },
    backArrowStyle() {
      return {
        visibility: this.canShowBackArrow ? "visible" : "hidden",
      };
    },
    hasOtherModesEnabled() {
      return this.cafe.modes.preorder + this.cafe.modes.delivery + 1 > 1;
    },
    canShowBackArrow() {
      return this.hasOtherModesEnabled || MarketplaceService.get().flag;
    },
    reservationMade() {
      return !!this.reservation && this.reservation.status == "confirmed";
    },
    reservationDates() {
      let items = [];

      if (!this.cafe.schedule) return [];

      //
      // SOME NICE CODE WITH TIME AND DATES INCOMING!
      //
      //

      let now = moment().add(1, "day");

      if (this.cafe.reserve.allow_same_day) {
        now = moment();
      }

      let end = moment().add(this.maxDaysForDate, "days");

      while (now.isBefore(end)) {
        const helper = new ScheduleService.ScheduleHelper(
          this.cafe.schedule,
          now.valueOf()
        );

        if (!helper.isWorkday()) {
          now = now.add(1, "day");
          continue;
        }

        const day_start = helper.startMoment;
        const day_end = helper.endMoment;

        let day_ptr = moment(day_start);

        while (day_ptr.isBefore(day_end)) {
          if (day_ptr.isBefore(moment())) {
            day_ptr = day_ptr.add(1, "hour");
            continue;
          }

          items.push(day_ptr.toDate());

          day_ptr = day_ptr.add(1, "hour");
        }

        now = now.add(1, "day");
      }

      return items;
    },
    showArrowBack() {
      if (this.reservationMade) return false;
      if (MarketplaceService.get().flag) return true;

      // return this.hasOtherModesEnabled;
      return true;
    },
  },
  filters: {
    formatReservationTime(raw) {
      return moment(raw).format("HH:mm");
    },
    formatReservationDate(raw) {
      return moment(raw).format("DD.MM.YYYY");
    },
  },
  mounted() {
    window.document.body.style.backgroundColor = "#ededed";
    if (this.isDarkMode) window.document.body.style.backgroundColor = "black";
    if (!MarketplaceService.isValid(this.$route.params.code)) {
      this.$router.push("/marketplace");
      return;
    }

    this.reservation_info.marketplace_order = MarketplaceService.get().flag;

    this.$store
      .dispatch("clients/init")
      .then((ok) => {
        this.fetchData();
      })
      .catch((error) => {
        this.error = error;
      });
  },
  components: {
    OrderLoader,
    LoadError,
    PhoneConfirmModal,
    OnlinePaymentModal,
    OrderModalTemplate,
    ClientNameField,
    ClientPhoneField,
    ChooseTimeField,
    PersonsAmountField,
    CommentField,
    ContactIcons,
    Navbar,
    SupportIcon,
    PromocodeInput,
    ReserveInfo,
    FeedbackButton,
    PayMethods,
    CafeFeedback,
    OrderStatusNotification,
    PhoneConfirmationUI,
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>

<template>
  <div class="order-detail" v-thematic>
    <select :value="value" @input="$emit('input', $event.target.value)">
      <option v-if="isCutlery" :value="0">
        {{ $tc("preorder_details.guests_count_suffix", 0) }}
      </option>
      <option v-for="n in max" :key="n" :value="n">
        {{isCutlery
          ? $tc("preorder_details.guests_count_suffix", n)
          : $tc("reserve.guests_count", n)}}
      </option>
    </select>
    <img
      v-if="value > 0 && isCutlery"
      :src="isDarkMode ? '/icons/details/cutlery-white.svg' : '/icons/details/cutlery.svg'"
      :class="!value ? 'disabled' : ''"
    />
    <img
      v-else-if="value > 0 && !isCutlery"
      :src="isDarkMode ? '/icons/persons-dark.svg' : '/icons/persons.svg'"
      :class="!value ? 'disabled' : ''"
    />

    <img src="/icons/details/cutlery-eco.svg" v-else/>

    <p :class="value == 0 ? 'selective':''">
      {{
        isCutlery
          ? $tc("preorder_details.guests_count_suffix", value)
          : $tc("reserve.guests_count", value)
      }}
    </p>

    <div class="selective-item">
      <img
        v-show="value !== 0"
        :src="isDarkMode ? '/icons/arrow-black.svg' : '/icons/arrow-white.svg'"
        :class="!value ? 'arrow disabled' : 'arrow'"
      />
      <img
        v-show="value == 0"
        :src="isDarkMode ? '/icons/arrow-green.svg' : '/icons/arrow-green.svg'"
        :class="!value ? 'arrow' : 'arrow'"
      />
    </div>
  </div>
</template>

<style scoped>
p.selective {
  color: #129b81;
}
</style>

<script>
export default {
  props: {
    max: {
      type: Number,
      default: 6,
    },
    value: {
      type: Number,
      default: 0,
    },
    isCutlery: {
      type: Boolean,
      default: false,
    },
  }
  
};
</script>